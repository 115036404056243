<template>
<div></div>
</template>
<script>
import abbottAndMixin from "@/mixins/liff/proj/abbott-and";

export default {
  mixins: [abbottAndMixin],
  components: {},
  async mounted() {
    await this.init();
  },
  data() {
    return {
      lineData: {},
    };
  },
  computed: {},
  methods: {
    async init() {
      await this.submit()
    },
  }
}
</script>

<style lang="scss"></style>
